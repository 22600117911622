import { useContext, useMemo } from "react";
import { useHistory } from "react-router";

import { usePermissions } from "../../utils/permissionsHook";
import { notify } from "../../utils/notify";
import { GlobalContext } from "../../contexts/GlobalContext";
import { GlobalVariables } from "../../GlobalVariables";

export default function useMenuDataOfficeController() {
  const { push } = useHistory();

  const {
    checkIfUserHasPartialPermission,
    handleProductMenuHide,
    validateProductStatus,
  } = usePermissions();

  const userPermissons = useMemo(() => {
    return {
      customerManagement: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CLIENTES_VISUALIZATION
      ),
      gestaoFacil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.GESTAO_FACIL
      ),
      contabil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CONTABIL
      ),
      fiscal: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.FISCAL
      ),
      folha: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.FOLHA),

      cnd: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CND_VISUALIZATION
      ),
      crm: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.CRM),
      xml: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.XML),
      settings: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.SETTINGS
      ),
      documentsAndGuides: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.DOCUMENTS_AND_GUIDES
      ),
      attendance: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.ATTENDANCE
      ),
      nfs_management: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.NFS_MANAGEMENT
      ),
      radar: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.RADAR),
      permissionGroups: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.SETTINGS_USER
      ),
    };
  }, [checkIfUserHasPartialPermission]);

  const { companyUser, userLogged, paramsQyonBot } = useContext(GlobalContext);

  const isAccountant = !!companyUser.isAccountant;
  const isInClientView = sessionStorage.getItem("isInClientView");
  const isClient =
    (!companyUser.isAccountant && !!companyUser.accountantId) || isInClientView;
  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  const optionsClient = [
    {
      label: "Atendimentos",
      value: "ATTENDANCE",
      hasASubMenu: false,
      icon: "AtendimentoIcon",
      path: "/attendance/search",
      hide: isAccountant || isStandaloneClient,
      standard: true,
      blur:
        isInClientView ||
        !validateProductStatus("CRM").isActive ||
        !userPermissons.attendance,
      customTooltip: isInClientView
        ? "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
        : validateProductStatus("CRM").message ??
          (!userPermissons.attendance && "Acesso restrito ao usuário"),
      functionMenu: () => {
        if (isInClientView) {
          return notify(
            "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
          );
        }

        if (!validateProductStatus("CRM").isActive) {
          return notify(validateProductStatus("CRM").message);
        }

        if (!userPermissons.attendance) {
          return notify("Acesso não liberado.");
        }

        push("/attendance/search");
      },
    },
  ];

  let optionsMenu = [
    {
      label: "Dashboard",
      value: "DASHBOARD",
      hasASubMenu: false,
      icon: "DashboardIcon",
      path: "/dashboard/view",
      standard: true,
    },
    {
      standard: true,
      hasASubMenu: false,
      label: "Loja de Aplicativos",
      value: "APPS_STORE",
      icon: "AppStoreIcon",
      path: "/apps-store",
    },
    {
      label: "Gestão de Clientes",
      value: "CLIENT_MANAGEMENT",
      icon: "BpoIcon",
      path: "/customermanagement/list",
      hide: isClient || isStandaloneClient,
      blur: !userPermissons.customerManagement,
      customTooltip:
        !userPermissons.customerManagement && "Acesso restrito ao usuário",
      standard: true,
    },

    {
      // systemId: GlobalVariables.SYSTEMS.CRM,
      standard: true,
      hasASubMenu: false,
      label: "Documentos e Guias",
      value: "GUIDE",
      icon: "GuiasIcon",
      path: "/guide",
      hide: isStandaloneClient,
      blur: !userPermissons.documentsAndGuides,
      customTooltip: !userPermissons.documentsAndGuides
        ? "Acesso restrito ao usuário"
        : null,
    },

    // {
    //   label: "Usuários e Acesso",
    //   value: "PERMISSIONS",
    //   hasASubMenu: false,
    //   icon: "PermissionGroupIcon",
    //   path: "/useraccess/management",
    //   blur: !userPermissons.permissionGroups,
    //   customTooltip: !userPermissons.permissionGroups && "Usuários e Acesso",
    //   standard: true,
    //   isBeta: false,
    // },

    {
      standard: true,
      hasASubMenu: false,
      label: "Feed",
      hide: true,
      value: "FEED",
      icon: "FeedIcon",
      path: "/feed",
    },

    {
      label: "Apps",
      value: "PRODUCTS",
    },

    {
      hide:
        isAccountant ||
        isStandaloneClient ||
        !validateProductStatus("Folha").isActive,

      systemId: GlobalVariables.SYSTEMS.Folha,
      label: "Folha de Pagamento",
      // : "Simulação Folha",
      value: "PAYROLL",
      icon: "ContabilIcon",
      pathExternal: `/panel/${userLogged.token}?company_document=${
        !isClient ? companyUser.document : companyUser.accountantCnpj
      }${isClient ? "&company_access=" + companyUser.document : ""}`,
      hasPermission: userPermissons.folha,
      blur: !userPermissons.folha,
      customTooltip:
        validateProductStatus("Folha").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "Folha" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.folha && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (isInClientView) {
          return notify("Essa função não está disponível na visão de cliente.");
        }

        if (!userPermissons.folha) {
          return notify("Acesso não liberado.");
        }

        if (isStandaloneClient && handleProductMenuHide({ product: "Folha" })) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!validateProductStatus("Folha").isActive) {
          return notify(validateProductStatus("Folha").message);
        }

        window.open(pathExternal, "_blank");
      },
    },

    {
      systemId: GlobalVariables.SYSTEMS.Fiscal,
      SubMenu: false,
      label: "Automação Fiscal",
      value: "TAX_WRITING",
      icon: "ContabilIcon",
      pathExternal: `/panel/${userLogged.token}?company_document=${companyUser.document}`,
      hide: true,
      hasPermission: userPermissons.fiscal,
      blur:
        handleProductMenuHide({
          product: "Fiscal",
          mustBeActive: true,
        }) || !userPermissons.fiscal,
      customTooltip:
        validateProductStatus("Fiscal").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "Fiscal" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.fiscal && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.fiscal) {
          return notify("Acesso não liberado.");
        }

        if (
          isStandaloneClient &&
          handleProductMenuHide({ product: "Fiscal" })
        ) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!validateProductStatus("Fiscal").isActive) {
          return notify(validateProductStatus("Fiscal").message);
        }

        window.open(pathExternal, "_blank");
      },
    },
    {
      hide:
        isClient ||
        (!validateProductStatus("Fiscal").isActive &&
          !validateProductStatus("Contabil").isActive &&
          !validateProductStatus("Folha").isActive),
      systemId: GlobalVariables.SYSTEMS.Contabil,
      label: "Solução contábil",
      value: "ACCOUNTING",
      hasASubMenu: true,
      icon: "ContabilIcon",
      blur:
        !userPermissons.contabil &&
        !userPermissons.fiscal &&
        !userPermissons.folha,
    },

    {
      systemId: GlobalVariables.SYSTEMS.ERP,
      label: "Gestão Financeira",
      shouldPulse:
        companyUser?.isGfTrialFirstAccess && userLogged?.showVideoIntro,
      hasASubMenu: false,
      value: "ERP",
      icon: "GFIcon",
      pathExternal: `/zf/login/loginpanel?tokenCognito=${userLogged.token}&document=${companyUser.document}`,
      hide: !validateProductStatus("ERP").isActive,
      // handleProductMenuHide({
      //   product: "ERP",
      //   mustBeActive: true,
      //   isMaster: userLogged.userId === companyUser.masterUserId,
      // }),
      blur: !userPermissons.gestaoFacil,
      customTooltip:
        validateProductStatus("ERP").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "ERP" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.gestaoFacil && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.gestaoFacil) {
          return notify("Acesso não liberado.");
        }

        if (handleProductMenuHide({ product: "ERP" })) {
          return notify("Acesso não liberado.");
        }

        if (!validateProductStatus("ERP").isActive) {
          if (
            validateProductStatus("ERP").status === "E" &&
            userLogged.userId === companyUser.masterUserId
          ) {
            window.open(
              `${process.env.REACT_APP_ERP_PLAN_STORE}?companyDocument=${
                companyUser?.document
              }&token=${localStorage.getItem(
                "tokenCognitoPanel"
              )}&fromSystem=portal`,
              "_blank"
            );
            push("/login", { logout: true });
          } else {
            return notify(validateProductStatus("ERP").message);
          }
        }

        window.open(pathExternal, "_blank");
      },
    },

    {
      systemId: GlobalVariables.SYSTEMS.XML,
      hasASubMenu: false,
      label: "XML",
      value: "XML",
      icon: "XmlIcon",
      pathExternal: `#/remote?token=${userLogged.tokenCognito}&cnpj=${companyUser.document}&painel=true`,
      hide: !validateProductStatus("XML").isActive,
      blur: !userPermissons.xml,
      customTooltip:
        validateProductStatus("XML").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "XML" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.xml && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.xml) {
          return notify("Acesso não liberado.");
        }

        if (isStandaloneClient && handleProductMenuHide({ product: "XML" })) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!validateProductStatus("XML").isActive) {
          return notify(validateProductStatus("XML").message);
        }

        window.open(pathExternal, "_blank");
      },
    },
    {
      hide:
        isClient ||
        isStandaloneClient ||
        !validateProductStatus("XML").isActive,
      label: "Radar",
      value: "RADAR",
      hasASubMenu: true,
      blur: !userPermissons.radar,
      icon: "RadarIcon",
    },
    {
      systemId: GlobalVariables.SYSTEMS.BANK,
      label: "Qyon Bank",
      hasASubMenu: false,
      value: "BANK",
      // hide: false,
      // hide: !paramsQyonBot?.haveBankAccount,
      icon: "BankIcon",
      pathExternal: `https://bank.qyon.com/`,
      customTooltip: "Acessar Qyon Bank",
      forceRedirect: true,
    },
    {
      systemId: GlobalVariables.SYSTEMS.CRM,
      label: "Atendimentos",
      value: "ATTENDANCE_ACCOUNTANT",
      hasASubMenu: false,
      icon: "AtendimentoIcon",
      hide:
        isStandaloneClient ||
        !isAccountant ||
        !validateProductStatus("CRM").isActive,
      blur: !userPermissons.attendance,

      customTooltip:
        validateProductStatus("CRM").message ??
        (handleProductMenuHide({ product: "CRM" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.attendance && "Acesso restrito ao usuário"),
      functionMenu: () => {
        if (isInClientView) {
          return notify(
            "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
          );
        }

        if (handleProductMenuHide({ product: "CRM" })) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!userPermissons.attendance) {
          return notify("Acesso não liberado.");
        }

        push("/attendance/search");
      },
    },
    {
      standard: true,
      hasASubMenu: false,
      label: "Backup Seguro",
      value: "BACKUP",
      icon: "BackupIcon",
      path: "/backup",
      hide: isClient || isStandaloneClient,
      blur: false,
    },
  ];

  if (isClient) {
    optionsMenu.splice(0, 0, ...optionsClient);
  } else {
    optionsMenu.splice(5, 0, ...optionsClient);
  }

  // useEffect(() => {
  //   console.log(paramsQyonBot);
  //   console.log("PIRACANJUBA");
  // }, [paramsQyonBot]);

  const optionSubMenus = useMemo(
    () => ({
      RADAR: [
        {
          label: "CNDs",
          blur:
            !userPermissons.cnd ||
            (handleProductMenuHide({ product: "ERP", mustBeActive: true }) &&
              handleProductMenuHide({ product: "XML", mustBeActive: true })),
          hasPermission:
            !handleProductMenuHide({
              product: "ERP",
              mustBeActive: true,
            }) ||
            !handleProductMenuHide({
              product: "XML",
              mustBeActive: true,
            }),
          hide: !validateProductStatus("XML").isActive,
          functionMenu: () => {
            if (!userPermissons.cnd) {
              return notify("Acesso não liberado.");
            } else {
              let userId = localStorage.getItem("userIdPanel");
              let document = localStorage.getItem("documentPanel");
              let token = localStorage.getItem("tokenCognitoPanel");
              let baseUrl =
                localStorage.getItem("environment") === "prod"
                  ? "https://radar.qyon.com/"
                  : "https://radar-staging.qyon.com/";

              const url = `${baseUrl}#/?iduser=${userId}&document=${document}&page=cnd&token=${token}`;
              window.open(url, "_blank");
            }
          },
        },
        {
          label: "ECAC",
          blur:
            !userPermissons.radar ||
            (handleProductMenuHide({ product: "ERP", mustBeActive: true }) &&
              handleProductMenuHide({ product: "XML", mustBeActive: true })),
          hasPermission:
            !handleProductMenuHide({
              product: "ERP",
              mustBeActive: true,
            }) ||
            !handleProductMenuHide({
              product: "XML",
              mustBeActive: true,
            }),
          hide: isClient || !validateProductStatus("XML").isActive,
          functionMenu: () => {
            if (!userPermissons.cnd) {
              return notify("Acesso não liberado.");
            } else {
              let userId = localStorage.getItem("userIdPanel");
              let document = localStorage.getItem("documentPanel");
              let token = localStorage.getItem("tokenCognitoPanel");
              let baseUrl =
                localStorage.getItem("environment") === "prod"
                  ? "https://radar.qyon.com/"
                  : "https://radar-staging.qyon.com/";

              const url = `${baseUrl}/#/?iduser=${userId}&document=${document}&page=ecac&token=${token}`;
              window.open(url, "_blank");
            }
          },
        },
      ],
      ACCOUNTING: [
        {
          systemId: GlobalVariables.SYSTEMS.Contabil,
          label: "Contabilidade",
          value: "ACCOUNTING",
          hasASubMenu: false,
          icon: "ContabilIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${companyUser.document}`,
          hide: isClient || !validateProductStatus("Contabil").isActive,
          hasPermission: userPermissons.contabil,
          blur: !userPermissons.contabil,
          customTooltip:
            validateProductStatus("Contabil").message ??
            (isStandaloneClient &&
            handleProductMenuHide({ product: "Contabil" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.contabil && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (!userPermissons.contabil) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Contabil" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Contabil").isActive) {
              return notify(validateProductStatus("Contabil").message);
            }

            window.open(pathExternal, "_blank");
          },
        },
        {
          systemId: GlobalVariables.SYSTEMS.Fiscal,
          SubMenu: true,
          label: "Escrita Fiscal",
          value: "TAX_WRITING",
          icon: "EscritaIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${companyUser.document}`,
          hide: isClient || !validateProductStatus("Fiscal").isActive,
          hasPermission: userPermissons.fiscal,
          blur: !userPermissons.fiscal,
          customTooltip:
            validateProductStatus("Fiscal").message ??
            (isStandaloneClient && handleProductMenuHide({ product: "Fiscal" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.fiscal && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (!userPermissons.fiscal) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Fiscal" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Fiscal").isActive) {
              return notify(validateProductStatus("Fiscal").message);
            }

            window.open(pathExternal, "_blank");
          },
        },
        {
          systemId: GlobalVariables.SYSTEMS.Folha,
          SubMenu: true,
          label: "Folha de Pagamento",
          // : "Simulação Folha",
          value: "PAYROLL",
          icon: "FolhaIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${
            !isClient ? companyUser.document : companyUser.accountantCnpj
          }${isClient ? "&company_access=" + companyUser.document : ""}`,
          hasPermission: userPermissons.folha,
          blur: !userPermissons.folha,
          hide: !validateProductStatus("Folha").isActive,
          customTooltip:
            validateProductStatus("Folha").message ??
            (isStandaloneClient && handleProductMenuHide({ product: "Folha" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.folha && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (isInClientView) {
              return notify(
                "Essa função não está disponível na visão de cliente."
              );
            }

            if (!userPermissons.folha) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Folha" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Folha").isActive) {
              return notify(validateProductStatus("Folha").message);
            }

            window.open(pathExternal, "_blank");
          },
        },

        {
          systemId: GlobalVariables.SYSTEMS.Folha,
          SubMenu: true,
          label: "Migrações eSocial",
          value: "ESOCIAL",
          icon: "FolhaIcon",
          hasPermission: userPermissons.customerManagement,
          hide: !validateProductStatus("Folha").isActive,
          blur: !userPermissons.customerManagement,
          customTooltip:
            !userPermissons.customerManagement && "Acesso restrito ao usuário",
          functionMenu: () => {
            if (isInClientView) {
              return notify(
                "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
              );
            }

            if (!userPermissons.customerManagement) {
              return notify("Acesso não liberado.");
            }

            push("/esocial/migrations");
          },
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userPermissons]
  );

  return {
    userPermissons,
    isAccountant,
    isClient,
    isStandaloneClient,
    isInClientView,
    optionsMenu,
    optionSubMenus,
    push,
    handleProductMenuHide,
    validateProductStatus,
  };
}
