import React, { useEffect, useRef, useState } from "react";

import {
  Grid,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Chip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { createCompanyAutomaticallyFilled } from "./events/CreateCompany";

import { getMP3Audio } from "../../../repositories/AudioHandler";
import { readCertificate } from "../../../repositories/Bot";

import { notify } from "../../../utils/notify";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Certificate from "../../../assets/images/Illlustration.png";

import listCities from "../../../utils/cities.json";
import listStates from "../../../utils/states.json";

import BotCopilotGif from "../../../assets/gifs/bot_copilot.gif";

import {
  BodyBox,
  DivBodyDescription,
  GridStyled,
} from "./styles/CertificateModal";

import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import MaskService from "../../../utils/mask/maskService";
import { Autocomplete } from "@material-ui/lab";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ModalUpdateCertificate } from "./UpdateCertificateModal";
import { getAccountantClientDataByDocument } from "../../../repositories/portal_api/AccountantClient";
import { validEmail } from "../../../utils/validations";
import LogoQyonInteligence from "../../../components/logos/LogoQyonInteligence";
import CertificateSystemActionsBody from "./CertificateSystemActionsBody";
import { a1UploadAndProcuration } from "../../../repositories/panel/v1/CertificateRepository";
import { GlobalVariables } from "../../../GlobalVariables";
import { usePermissions } from "../../../utils/permissionsHook";
import {
  getAddressDataInBigDataByCEP,
  getCompanyDataInBigDataByDocument,
} from "../../../repositories/portal_api/BigData";

export const CertificateModal = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [screen, setScreen] = useState("CERTIFICATE_DATA");
  const [companiesNotRegistered, setCompaniesNotRegistered] = useState(null);

  const [listCompaniesNotCreated, setListCompaniesNotCreated] = useState([]);
  const [listCompaniesCreated, setListCompaniesCreated] = useState([]);

  const [entitiesExistant, setEntitiesExistant] = useState([]);

  const [actionForAll, setActionForAll] = useState("company");
  const [isStandAlone, setIsStandAlone] = useState(false);

  const [certificateListState, setCertificateListState] = useState(
    props.certificateList
  );
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { document } = companyUser;
  const { token } = userLogged;
  const certificateError = useRef("");
  const responseError = useRef("");
  const success = useRef(true);
  const flagCompanyExists = useRef(true);

  const { checkIfUserHasPermission } = usePermissions();

  const closeCertificateModal = () => {
    if (screen === "CERTIFICATE_SYSTEM_ACTIONS") {
      setScreen("ANALYSYS");
    } else if (screen === "ANALYSYS") {
      setScreen("CERTIFICATE_DATA");
    } else {
      props.closeCertificateModal();
    }
  };

  const validateEmails = () => {
    let emptyEmail = false;
    certificateListState.forEach((certificate, index) => {
      const companyBody = certificate.company;

      if (certificate.inviteCreateCompany) {
        if (!companyBody.email) {
          notify(
            "Email de acesso não informado para a empresa " +
              companyBody.companyName
          );
          emptyEmail = true;
        }
      }

      if (certificate.procurationCompanyList) {
        certificate.procurationCompanyList.forEach((procuration, index) => {
          const procurationBody = procuration.company;

          if (procuration.inviteCreateCompany) {
            if (!procurationBody.email) {
              notify(
                "Email de acesso não informado para a empresa " +
                  procurationBody.companyName
              );
              emptyEmail = true;
            }
          }
        });
      }
    });

    return !emptyEmail;
  };

  const getTitle = () => {
    return certificateListState.length > 1
      ? `Detectei que estes arquivos são ${certificateListState.length} certificados A1`
      : "Detectei que este arquivo é um certificado A1";
  };

  const getSecondTitle = () => {
    if (
      certificateListState.length === 1 &&
      certificateListState.filter((e) => e.action === "procuration").length ===
        1
    ) {
      return "Detectei um certificado de procuração. Posso armazenar em meu sistema?";
    }

    let message =
      companiesNotRegistered === 0 || companiesNotRegistered === null
        ? certificateListState.length > 1
          ? `Empresas já cadastradas. Deseja atualizar o certificado A1 dessas ${certificateListState.length} empresas?`
          : "Empresa já cadastrada. Deseja atualizar seu Certificado A1?"
        : companiesNotRegistered > 1
        ? `Encontrei ${companiesNotRegistered} empresas não cadastradas. Posso cadastrar essa empresa como cliente do escritório?`
        : "Encontrei 1 empresa não cadastrada. Posso cadastrar essa empresa como cliente no escritório?";

    if (
      certificateListState.filter((e) => e.action === "procuration").length > 1
    ) {
      message +=
        " No caso do(s) certificado(s) de procuração, irei inseri-lo(s) no sistema.";
    }

    return message;
  };

  const saveUpdateCertificate = async () => {
    setDisabled(true);
    try {
      for (let certificate of certificateListState) {
        if (certificate.action === "procuration") {
          await handleA1UploadProcurationEvent(certificate);
        } else {
          await handleA1UploadEvent(certificate);
        }
      }
      notify("Operação realizada com sucesso!", true, "success");
      setDisabled(false);
      props.closeCertificateModal();
    } catch (error) {
      setDisabled(false);
      notify(
        `Ocorreu um problema no certificado ${certificateError.current.name}! Tente novamente!`
      );
      setScreen("ANALYSYS");
    }
  };

  const handleA1UploadEvent = async (certificate) => {
    let companyBody = certificate.company;

    if (certificate.inviteCreateCompany) {
      if (!companyBody.email) {
        notify(
          "Email de acesso não informado. Por favor, informe o email para acesso ao Portal do seu cliente."
        );
        certificateError.current = certificate;
        throw new Error();
      }

      if (companyBody.email && validEmail(companyBody.email) === false) {
        notify(
          "Email de acesso inválido. Por favor, informe um email válido para acesso ao Portal do seu cliente."
        );
        certificateError.current = certificate;
        throw new Error();
      }

      companyBody.email = companyBody.email.trim().toLowerCase();

      if (companyBody?.existsInPortal) {
        delete companyBody.existsInPortal;
      }

      if (companyUser.isAccountant || companyUser.accountantId) {
        if (document !== companyBody.clientCnpj) {
          let responseCreateCompany = await createCompanyAutomaticallyFilled(
            companyBody,
            token,
            document,
            entitiesExistant,
            certificate?.company?.clientCnpj
          );

          if (!responseCreateCompany?.success) {
            certificateError.current = certificate;
            throw new Error();
          }
        }
      }
    }

    let responseA1 = await a1UploadAndProcuration({
      event: "A1_UPLOAD",
      certificate: {
        certificateName: certificate.name,
        certificateBase64: certificate.base64,
        certificatePassword: certificate.password,
        certificateCNPJ: companyBody.clientCnpj,
      },
      migrate: certificate?.migrate,
      sub: localStorage.getItem("subCognitoPanel"),
    });

    if (!responseA1?.success) {
      certificateError.current = certificate;
      throw new Error();
    }
  };

  const handleA1UploadProcurationEvent = async (certificate) => {
    await handleCertificateCompany(certificate);
    await formatAndSendA1UploadProcuration(certificate);
    await handleProcurationCompanyList(certificate);
  };

  const handleCertificateCompany = async (certificate) => {
    let companyBody = certificate.company;
    if (certificate.inviteCreateCompany) {
      if (!companyBody.email) {
        notify(
          "Email de acesso não informado. Por favor, informe o email para acesso ao Portal do seu cliente."
        );
        certificateError.current = certificate;
        throw new Error();
      }

      if (document !== companyBody.clientCnpj) {
        let responseCreateCompany = await createCompanyAutomaticallyFilled(
          companyBody,
          token,
          document,
          entitiesExistant,
          certificate?.company?.clientCnpj
        );

        if (!responseCreateCompany?.success) {
          certificateError.current = certificate;
          throw new Error();
        }
      }
    }
  };

  const formatAndSendA1UploadProcuration = async (certificate) => {
    const formatedCNPJList = formatCNPJList(
      certificate.procurationDocumentList
    );

    let responseA1 = await a1UploadAndProcuration({
      event: "A1_UPLOAD_PROCURATION",
      certificate: {
        certificateName: certificate.name,
        certificateBase64: certificate.base64,
        certificatePassword: certificate.password,
        certificateProcurationDocumentList: formatedCNPJList,
      },
      sub: localStorage.getItem("subCognitoPanel"),
    });

    if (!responseA1?.success) {
      certificateError.current = certificate;
      throw new Error();
    }
  };

  const handleProcurationCompanyList = async (certificate) => {
    if (certificate.procurationCompanyList) {
      for (let procuration of certificate.procurationCompanyList) {
        let procurationBody = procuration.company;

        if (procuration.inviteCreateCompany) {
          if (!procurationBody.email) {
            notify(
              "Email de acesso não informado. Por favor, informe o email para acesso ao Portal do seu cliente."
            );
            certificateError.current = certificate;
            throw new Error();
          }

          if (
            procurationBody.email &&
            validEmail(procurationBody.email) === false
          ) {
            notify(
              "Email de acesso inválido. Por favor, informe um email válido para acesso ao Portal do seu cliente."
            );
            certificateError.current = certificate;
            throw new Error();
          }

          procurationBody.email = procurationBody.email.trim().toLowerCase();

          if (
            procuration.inviteCreateCompany &&
            document !== procurationBody.clientCnpj
          ) {
            const responseCreateCompanyProcuration =
              await createCompanyAutomaticallyFilled(
                procurationBody,
                token,
                document,
                entitiesExistant,
                certificate?.company?.clientCnpj,
                true
              );
            if (responseCreateCompanyProcuration === null) {
              certificateError.current = certificate;
              throw new Error();
            }
          }
        }
      }
    }
  };

  const nextPageSaveCertificate = async () => {
    setDisabled(true);
    let i = 0;

    let companiesNotCreated = [];
    let companiesCreated = [];

    let copyCertificateList = [...certificateListState];
    let entitiesExistantCopy = [...entitiesExistant];

    try {
      for (let certificate of copyCertificateList) {
        certificate["inviteCreateCompany"] = true;

        const emptyPassword = checkEmptyCertificatePassword(certificate, i);

        if (emptyPassword) {
          break;
        }

        if (certificate.action === "procuration") {
          const invalidOrEmptyDocumentProcuration =
            checkIfDocumentProcurationIsValid(certificate, i);

          if (invalidOrEmptyDocumentProcuration) {
            break;
          }
        }
        const invalidCertificateData = await handleCertificateRead(
          certificate,
          entitiesExistantCopy,
          companiesNotCreated,
          companiesCreated,
          i
        );

        if (invalidCertificateData) {
          break;
        }

        if (certificate.action === "procuration") {
          certificate.procurationCompanyList = [];

          for (let document of certificate.procurationDocumentList) {
            const isNotValid = await handleProcuration(
              document,
              certificate,
              entitiesExistantCopy,
              companiesCreated,
              companiesNotCreated
            );

            if (isNotValid) {
              break;
            }
          }
        }

        if (success.current) {
          i++;
          setCertificateListState(copyCertificateList);
        }
      }
    } catch (error) {
      notify(`Ocorreu um problema no certificado !`);
    }

    if (success.current) {
      setDisabled(false);

      setCompaniesNotRegistered(companiesNotCreated.length);

      setListCompaniesNotCreated(companiesNotCreated);
      setListCompaniesCreated(companiesCreated);

      setScreen("ANALYSYS");

      let title =
        listCompaniesNotCreated.length === 0 ||
        listCompaniesNotCreated.length === null
          ? certificateListState.length > 1
            ? `Detectei que essas empresas já estão cadastradas. Posso atualizar o certificado A1 dessas ${certificateListState.length} empresas?`
            : "Detectei que essa empresa já está cadastrada. Posso atualizar o certificado A1 dela?"
          : listCompaniesNotCreated.length > 1
          ? `Encontrei ${listCompaniesNotCreated.length} empresas não cadastradas. Posso cadastrar essas empresas como cliente do escritório?`
          : "Encontrei 1 empresa não cadastrada. Posso cadastrar essa empresa como cliente no escritório?";

      if (
        certificateListState.filter((e) => e.action === "procuration").length >
        1
      ) {
        title +=
          " No caso do(s) certificado(s) de procuração, irei inseri-lo(s) no sistema.";
      }

      if (
        certificateListState.length === 1 &&
        certificateListState.filter((e) => e.action === "procuration")
          .length === 1
      ) {
        title = "Posso inserir o certificado de procuração no sistema?";
      }

      getMP3Audio(title).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });
    } else {
      setDisabled(false);

      try {
        if (responseError.current) {
          notify(responseError.current.message);
        }
      } catch (err) {
        notify(
          `Ocorreu um problema ao tentar checar o certificado ${certificateError.current.name}! Tente novamente!`
        );
      }
    }
    setEntitiesExistant(entitiesExistantCopy);
    setDisabled(false);
  };

  const checkEmptyCertificatePassword = (certificate, i) => {
    if (certificate.password === undefined || certificate.password === "") {
      notify(`Informe a senha do seu certificado!`);
      certificateError.current = certificate;
      let certList = [...certificateListState];
      certList[i].passwordError = true;
      success.current = false;
      setCertificateListState(certList);
      setDisabled(false);
      return true;
    }
  };

  const checkIfDocumentProcurationIsValid = (certificate, i) => {
    if (certificate.procurationDocumentList.length === 0) {
      setDocumentInput(certificate.procurationDocument, i);
    }

    if (certificate.procurationDocumentList.length === 0) {
      notify(`Informe no minímo um CPF/CNPJ para a procuração!`);
      success.current = false;
      certificateError.current = certificate;
      let certList = [...certificateListState];
      certList[i].procurationError = true;
      setCertificateListState(certList);
      setDisabled(false);
      return true;
    }
  };

  const handleCertificateRead = async (
    certificate,
    entitiesExistantCopy,
    companiesNotCreated,
    companiesCreated,
    i
  ) => {
    responseError.current = null;
    success.current = true;
    let responseCompany = await readCertificate(certificate);
    if (responseCompany !== null && responseCompany.success === true) {
      flagCompanyExists.current = false;

      const entityObject = buildCompanyObject(responseCompany, certificate);
      certificate.company = entityObject;

      let entityExists = await getAccountantClientDataByDocument(
        certificate.company.clientCnpj
      );

      const dontHavePermission = checkCompanyAndUserPermissionToEntity(
        entityExists,
        certificate
      );
      if (dontHavePermission) {
        return true;
      }

      const entityAlreadyInAnotheAccountant = handleIfEntityExist(
        entityExists,
        certificate,
        entitiesExistantCopy,
        responseCompany
      );

      if (entityExists?.data?.email) {
        certificate.company.email = entityExists?.data?.email;
      } else {
        certificate.company.needsEmail = true;
      }

      if (entityAlreadyInAnotheAccountant) {
        return true;
      }

      if (flagCompanyExists.current) {
        certificate.company["existsInPortal"] = true;
        companiesCreated.push(certificate.company);
      } else {
        companiesNotCreated.push(certificate.company);
      }
    } else {
      success.current = false;
      certificateError.current = certificate;

      responseError.current = responseCompany;
      setDisabled(false);

      if (responseCompany.message === "Senha do certificado incorreta") {
        let list = [...certificateListState];
        list[i].passwordError = true;
        setCertificateListState(list);
      }
      return true;
    }
  };

  const buildCompanyObject = (responseCompany, certificate) => {
    let obj = {};

    obj.companyName =
      responseCompany?.companyName || responseCompany?.clientCnpj;
    obj.tradingName =
      responseCompany?.tradingName != null
        ? responseCompany?.tradingName || ""
        : responseCompany?.companyName || responseCompany?.clientCnpj;

    obj.clientCnpj = responseCompany?.clientCnpj;
    obj.phone = responseCompany?.phone || "";
    obj.email = certificate?.email || "";

    obj.addresses = [];

    let addr = {};

    if (
      responseCompany?.cep !== null &&
      responseCompany?.cep !== undefined &&
      responseCompany?.cep !== ""
    ) {
      addr.cep = responseCompany?.cep;
      addr.number = responseCompany?.number;
      addr.street = responseCompany?.street;
      addr.city = responseCompany?.city;
      addr.neighborhood = responseCompany?.district;
      addr.state = responseCompany?.uf;
      addr.complement = "";
      addr.ibgeCode = null;

      obj.addresses.push(addr);
    } else {
      obj.addresses.push({
        cep: "00000000",
        street: "Não Informado",
        number: "1",
        complement: "",
        neighborhood: "Não Informado",
        city: "Não Informado",
        state: "SP",
        ibgeCode: "",
      });
    }
    return obj;
  };

  const checkCompanyAndUserPermissionToEntity = (entityExists, certificate) => {
    if (
      entityExists?.code === 403 &&
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT)
    ) {
      success.current = false;
      notify(
        "Você não tem permissão para ver a empresa " +
          certificate?.company?.companyName
      );
      certificateError.current = certificate;
      setDisabled(false);
      return true;
    } else if (
      entityExists?.code === 403 &&
      !checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT)
    ) {
      success.current = false;
      notify(
        "Você não tem permissão a permissão (Gestão de Clientes > Gerenciamento) para realizar esta ação!"
      );
      certificateError.current = certificate;
      setDisabled(false);
      return true;
    }
    return false;
  };

  const handleIfEntityExist = (
    entityExists,
    certificate,
    entitiesExistantCopy,
    responseCompany
  ) => {
    if (entityExists !== null && entityExists.success === true) {
      entitiesExistantCopy.push(entityExists.data);
      certificate.company.isAccountant = entityExists.data.is_accountant;
      certificate.company.accountantId = entityExists.data.accountant_id;
      certificate.company.id = entityExists.data.id;
      certificate.company.companyName = entityExists.data.company_name;
      certificate.company.tradingName =
        entityExists.data?.trading_name || entityExists.data.company_name;
      certificate.company.phone =
        entityExists.data?.phone || responseCompany?.phone;
      flagCompanyExists.current = true;
      if (
        entityExists.data.accountant_id !== null &&
        entityExists.data.accountant_id !== undefined &&
        entityExists.data.accountant_id !== ""
      ) {
        if (
          entityExists.data.accountant_id.toString() !==
          localStorage.getItem("companyIdPanel")
        ) {
          success.current = false;
          notify(
            "A empresa " +
              certificate?.company?.companyName +
              " já está vinculada a outro escritório."
          );
          certificateError.current = certificate;
          setDisabled(false);
          return true;
        }
      }
    }
  };

  const handleProcuration = async (
    document,
    certificate,
    entitiesExistantCopy,
    companiesCreated,
    companiesNotCreated
  ) => {
    let obj = {
      name: certificate.name,
      inviteCreateCompany: true,
      action: "procurationToCreate",
      accessEmail: "",
      procurationDocument: "",
      procurationDocumentList: [],
      passwordError: false,
      procurationParent: certificate.company.clientCnpj,
      registerEmail: false,
    };

    let responseExistsPortal = await getAccountantClientDataByDocument(
      document.replace(/[^\d]/g, "")
    );

    if (responseExistsPortal?.success) {
      handleIfProcurationDocumentExistInPortal(
        entitiesExistantCopy,
        responseExistsPortal,
        obj,
        companiesCreated
      );
    } else if (responseExistsPortal?.code === 403) {
      success.current = false;
      certificateError.current = certificate;
      setDisabled(false);
      notify(
        `O CPF/CNPJ ${document} é ou já está vinculado a outro escritório.`
      );
      return true;
    } else {
      await handleIfProcurationDocumentNotExistInPortal(
        obj,
        companiesNotCreated,
        document
      );
    }
    certificate.procurationCompanyList.push(obj);
    success.current = true;
  };

  const handleIfProcurationDocumentExistInPortal = (
    entitiesExistantCopy,
    responseExistsPortal,
    obj,
    companiesCreated
  ) => {
    entitiesExistantCopy.push(responseExistsPortal.data);
    obj.company = {
      isAccountant: responseExistsPortal.data.is_accountant,
      accountantId: responseExistsPortal.data.accountant_id,
      id: responseExistsPortal.data.id,
      companyName: responseExistsPortal.data.company_name || "",
      tradingName:
        responseExistsPortal.data.trading_name ||
        responseExistsPortal.data.company_name ||
        "",
      clientCnpj: responseExistsPortal.data.document,
      email: responseExistsPortal.data.email || "",
      addresses: [
        {
          cep: "00000000",
          street: "Não Informado",
          number: "1",
          complement: "",
          neighborhood: "Não Informado",
          city: "Não Informado",
          state: "SP",
          ibgeCode: "",
        },
      ],
    };

    if (obj.company.email === "") {
      obj.registerEmail = true;
    }

    let objAddress = {};

    if (
      responseExistsPortal?.data?.addresses.filter(
        (addr) => addr.main_address === true
      ).length > 0
    ) {
      let address = responseExistsPortal?.data?.addresses.filter(
        (addr) => addr.main_address === true
      )[0];

      const city = listCities.find((value) => value?.id === address?.city_id);
      const state = listStates.find((value) => value?.id === address?.state_id);

      objAddress = {
        cep: address?.postal_code,
        street: address?.street,
        number: address?.address_number,
        complement: address?.address_complement,
        neighborhood: address?.district,
        city: city?.name,
        state: state?.uf,
        ibgeCode: address?.city_id,
      };
    } else {
      objAddress = {
        cep: "00000000",
        street: "Não Informado",
        number: "1",
        complement: "",
        neighborhood: "Não Informado",
        city: "Não Informado",
        state: "SP",
        ibgeCode: "",
      };
    }

    obj.company["existsInPortal"] = true;
    obj.company.addresses = [objAddress];
    companiesCreated.push(obj.company);
  };

  const handleIfProcurationDocumentNotExistInPortal = async (
    obj,
    companiesNotCreated,
    document
  ) => {
    let responseBigData = await getCompanyDataInBigDataByDocument(
      document.replace(/[^\d]/g, ""),
      companyUser.document
    );

    if (responseBigData?.success && responseBigData.ibgeCode !== null) {
      obj.company = {
        companyName: responseBigData.data.companyName || "",
        tradingName:
          responseBigData.data.tradingName ||
          responseBigData.data.companyName ||
          "",
        clientCnpj: responseBigData.data.clientCnpj || "",
        phone: responseBigData.data.phone || "",
        email: "",
      };

      obj.registerEmail = true;

      let objAddress = {};
      if (responseBigData?.result?.addresses) {
        const addressData = await getAddressDataInBigDataByCEP(
          responseBigData.data.addresses[0].cep
        );
        let ibgeCode = addressData?.data?.ibgeCode || "";

        objAddress = {
          cep: responseBigData.data.addresses[0].cep,
          street: responseBigData.data.addresses[0].street,
          number: responseBigData.data.addresses[0].number,
          complement: responseBigData.data.addresses[0].complement,
          neighborhood: responseBigData.data.addresses[0].neighborhood,
          city: responseBigData.data.addresses[0].city,
          state: responseBigData.data.addresses[0].state,
          ibgeCode: ibgeCode || "",
        };
      } else {
        objAddress = {
          cep: "00000000",
          street: "Não Informado",
          number: "1",
          complement: "",
          neighborhood: "Não Informado",
          city: "Não Informado",
          state: "SP",
          ibgeCode: "",
        };
      }

      obj.company.addresses = [objAddress];
    } else {
      // mocked body with name as cnpj
      obj.company = {
        companyName: document.replace(/[^\d]/g, ""),
        tradingName: document.replace(/[^\d]/g, ""),
        clientCnpj: document.replace(/[^\d]/g, ""),
        phone: "",
        email: "",
        addresses: [
          {
            cep: "00000000",
            street: "Não Informado",
            number: "1",
            complement: "",
            neighborhood: "Não Informado",
            city: "Não Informado",
            state: "SP",
            ibgeCode: "",
          },
        ],
      };

      obj.registerEmail = true;
    }
    companiesNotCreated.push(obj.company);
  };

  const setDocumentInput = (value, index) => {
    if (!Array.isArray(value)) {
      value = [value];
    }
    if (value.length === 0) {
      let certList = [...certificateListState];
      certList[index].procurationDocumentList = [];
      certList[index].procurationDocument = "";
      setCertificateListState(certList);
    } else {
      const arrayLength = value.length - 1;

      if (
        value[arrayLength].replace(/[^\d]/g, "").length !== 11 &&
        value[arrayLength].replace(/[^\d]/g, "").length !== 14
      ) {
        notify("Informe um CPF/CNPJ válido!");
        return;
      }

      let mask =
        value[arrayLength].replace(/[^\d]/g, "").length <= 11 ? "cpf" : "cnpj";

      value[arrayLength] = MaskService.toMask(mask, value[arrayLength]);

      let certList = [...certificateListState];
      certList[index].procurationDocumentList = value;
      certList[index].procurationDocument = value[arrayLength];
      certList[index].procurationError = false;

      setCertificateListState(certList);
    }
  };

  useEffect(() => {
    let title = getTitle();
    getMP3Audio(title).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
    if (companyUser.isAccountant || companyUser.accountantId) {
      setIsStandAlone(false);
    } else {
      setIsStandAlone(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatCNPJList = (list) => {
    let formatedList = [];

    list.forEach((item) => {
      const formatedCNPJ = item.replace(/[^\d]/g, "");
      formatedList.push(formatedCNPJ);
    });
    return formatedList;
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeCertificateModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {screen === "ANALYSYS" && (
          <ModalUpdateCertificate
            getSecondTitle={getSecondTitle}
            closeCertificateModal={closeCertificateModal}
            certificateListState={certificateListState}
            listCompaniesCreated={listCompaniesCreated}
            listCompaniesNotCreated={listCompaniesNotCreated}
            setListCompaniesCreated={setListCompaniesCreated}
            setListCompaniesNotCreated={setListCompaniesNotCreated}
            disabled={disabled}
            setDisabled={setDisabled}
            saveUpdateCertificate={async () => {
              if (validateEmails()) {
                setScreen("CERTIFICATE_SYSTEM_ACTIONS");
              }
            }}
            textButtonSave={"Continuar"}
            setCertificateListState={setCertificateListState}
          />
        )}

        {screen === "CERTIFICATE_SYSTEM_ACTIONS" && (
          <CertificateSystemActionsBody
            closeModal={closeCertificateModal}
            saveUpdateCertificate={saveUpdateCertificate}
            listCompaniesCreated={listCompaniesCreated}
            listCompaniesNotCreated={listCompaniesNotCreated}
            disabled={disabled}
          />
        )}
        {(!screen || screen === "CERTIFICATE_DATA") && (
          <DialogStyle>
            <DialogHeader>
              <div className="row">
                <LogoQyonInteligence></LogoQyonInteligence>
                <CloseIconStyle
                  onClick={() => {
                    props.closeCertificateModal();
                  }}
                />
              </div>
            </DialogHeader>

            <DivBodyDescription>
              <div
                style={{
                  fontSize: 21,
                  fontWeight: 700,
                  color: "#0036B3",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 10,
                  alignItems: "center",
                }}
              >
                Certificado A1 detectado.
                <img src={BotCopilotGif} alt="Qyon Intelligence"></img>
              </div>
              <Typography style={{ fontSize: 18, color: "#364461" }}>
                Por favor, insira a senha do seu Certificado A1 para acessar os
                dados. Com o certificado digital, você pode atualizar os dados
                da empresa ou, com procuração, migrar para o e-Social.
              </Typography>
            </DivBodyDescription>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <BodyBox>
              {certificateListState.length > 1 && (
                <Grid
                  container
                  style={{
                    marginTop: 15,
                    marginLeft: 30,
                  }}
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <FormControl size="small" variant="outlined">
                      <InputLabel>Tipo de ação</InputLabel>

                      <Select
                        label="Escolha uma ação"
                        value={actionForAll}
                        onChange={(e) => {
                          setActionForAll(e.target.value);
                        }}
                      >
                        <MenuItem value="company">
                          Cadastro/Atualização
                        </MenuItem>
                        {!isStandAlone && (
                          <MenuItem value="procuration">
                            Procuração (E-social)
                          </MenuItem>
                        )}
                        <MenuItem value="custom" disabled>
                          Personalizado
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} style={{ marginLeft: 20 }}>
                    <ButtonSave
                      disabled={actionForAll === "custom"}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        if (actionForAll !== "custom") {
                          let certList = [...certificateListState];

                          for (let i = 0; i < certList.length; i++) {
                            certList[i].action = actionForAll;

                            if (actionForAll === "procuration") {
                              certList[i].accessEmail = "";
                            } else {
                              certList[i].procurationDocument = "";
                              certList[i].procurationDocumentList = [];
                            }
                          }
                          setCertificateListState(certList);
                        }
                      }}
                    >
                      <Typography style={{ color: "white", fontSize: 16 }}>
                        Aplicar a todos
                      </Typography>
                    </ButtonSave>
                  </Grid>
                </Grid>
              )}

              {certificateListState.map((certificate, index) => {
                return (
                  <Grid key={"CERTIFICATE" + index} style={{ paddingLeft: 24 }}>
                    {certificateListState.length > 1 && (
                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    )}
                    <Grid
                      container
                      style={{
                        padding: certificateListState.length > 1 ? 10 : 24,
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          id="modal-modal-title"
                          style={{
                            marginBottom: 20,
                            fontSize: 18,
                            fontWeight: 500,
                          }}
                        >
                          {certificate.name}
                        </Typography>
                      </Grid>
                      <Grid item container spacing={1} xs={2}>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <img
                            alt="Certificado"
                            src={Certificate}
                            width="50"
                            style={{
                              marginTop:
                                certificate.action === "procuration" ? 15 : 0,
                              width: 80,
                              height: 80,
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        spacing={1}
                        xs={10}
                        alignItems="center"
                      >
                        <Grid item xs={6} style={{ paddingLeft: 24 }}>
                          <FormControl size="small" variant="outlined">
                            <InputLabel>Tipo de ação</InputLabel>

                            <Select
                              label="Escolha uma ação"
                              value={certificate.action}
                              onChange={(e) => {
                                setActionForAll("custom");

                                let list = [...certificateListState];
                                list[index].action = e.target.value;

                                if (e.target.value === "procuration") {
                                  list[index].accessEmail = "";
                                } else {
                                  list[index].procurationDocument = "";
                                  list[index].procurationDocumentList = [];
                                }
                                setCertificateListState(list);
                              }}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="company">
                                Cadastro/Atualização
                              </MenuItem>
                              {!isStandAlone && (
                                <MenuItem value="procuration">
                                  Procuração (E-social)
                                </MenuItem>
                              )}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 30 }}>
                          <TextField
                            size="small"
                            label="Senha do certificado"
                            type={"password"}
                            error={certificate.passwordError}
                            fullWidth
                            variant="outlined"
                            key={index}
                            onChange={(ev) => {
                              let certList = [...certificateListState];
                              certList[index].passwordError = false;
                              certList[index].password = ev.target.value;

                              setCertificateListState(certList);
                            }}
                          />
                        </Grid>

                        {certificate.action === "procuration" && (
                          <Grid
                            item
                            xs={12}
                            style={{ paddingLeft: 24, paddingTop: 10 }}
                          >
                            <Autocomplete
                              fullWidth
                              multiple
                              id="tags-filled"
                              options={[]}
                              freeSolo
                              value={certificate.procurationDocumentList}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    label={option}
                                    style={{ backgroundColor: "#f5f5f5" }}
                                    deleteIcon={
                                      <CloseIcon
                                        style={{
                                          height: 15,
                                          color: "#202020",
                                        }}
                                      />
                                    }
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="CPF/CNPJ da procuração"
                                  variant="outlined"
                                  key={index}
                                  error={certificate.procurationError}
                                  value={certificate.procurationDocument}
                                  onChange={(ev) => {
                                    certificate.procurationDocument =
                                      ev.target.value;
                                  }}
                                />
                              )}
                              onChange={(_, newValue) => {
                                setDocumentInput(newValue, index);
                              }}
                            />
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              style={{ marginBottom: 20 }}
                            >
                              * Aperte a tecla "Enter" para inserir os
                              CPFs/CNPJs
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: 0 }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={certificate.migrate || false}
                              onChange={(e) => {
                                let certList = [...certificateListState];
                                certList[index].migrate = e.target.checked;
                                setCertificateListState(certList);
                              }}
                              name="migrate"
                              color="primary"
                            />
                          }
                          label="Migrar e-Social"
                        />
                        <div style={{ color: "#364461" }}>
                          * Se alguma das empresas informadas tiver sido migrada
                          nos últimos 7 dias, ela não será migrada.
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </BodyBox>

            <GridStyled>
              <ButtonCancel
                style={{ marginRight: 16 }}
                onClick={() => {
                  if (screen === "CERTIFICATE_SYSTEM_ACTIONS") {
                    setScreen("ANALYSYS");
                  } else if (screen === "ANALYSYS") {
                    setScreen("CERTIFICATE_DATA");
                  } else {
                    props.closeCertificateModal();
                  }
                }}
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Cancelar
              </ButtonCancel>

              <ButtonSave
                background={disabled}
                endIcon={<ArrowForwardIcon />}
                onClick={async () => {
                  nextPageSaveCertificate();
                }}
              >
                {disabled ? "Carregando..." : "Continuar"}
              </ButtonSave>
            </GridStyled>
          </DialogStyle>
        )}
      </>
    </Modal>
  );
};
