import { useCallback, useContext, useRef } from "react";

import { useHistory } from "react-router";

import jwt_decode from "jwt-decode";

import Swal from "sweetalert2";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { getSystemsEntity } from "../../../repositories/panel/v1/EntityRepository";
import {
  postChangePassRecover,
  postCodeRecover,
} from "../../../repositories/panel/v1/integrations/CognitoRepository";
import {
  auth,
  changePasswordFirstTime,
  getEntities,
} from "../../../repositories/panel/v1/UserRepository";
import useAuthenticationTranslate from "../../../translations/authentications/Authentication";
import useCommonTranslate from "../../../translations/common/Common";
import { notify } from "../../../utils/notify";
import { usePermissions } from "../../../utils/permissionsHook";
import {
  validNotNull,
  validPasswordCoginito,
} from "../../../utils/validations";
import { LoginContext } from "../contexts/LoginContext";
import { getEntityDataById } from "../../../repositories/portal_api/AccountantClient";
import { syncEntitySystemVersions } from "../../../repositories/portal_api/Systems";
import { syncEntityUser } from "../../../repositories/portal_api/Users";

export default function useLoginController() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    sessionCognito,
    setSessionCognito,
    newPassword,
    confirmNewPassword,
    verificationCode,
    setErrors,
    setLoading,
    companyChecked,
    setCompanyChecked,
    setSendCodeVerif,

    listCompanies,
    setListCompanies,
  } = useContext(LoginContext);

  const isGfTrialFirstAccess = useRef(false);
  const {
    setUserLogged,
    setCompanyUser,
    userLogged,
    clearUserLogged,
    clearCompanyUser,
  } = useContext(GlobalContext);

  const translate = useAuthenticationTranslate();
  const translateUtils = useCommonTranslate();

  const { push } = useHistory();

  const {
    // getPermissionTreeAndSaveOnLocalStorage,
    // getUserPermissionsAndSaveOnLocalStorage,
    getPermissionTree,
    getUserPermissions,
    savePermissionTreeOnLocalStorage,
    saveUserPermissionsOnLocalStorage,
  } = usePermissions();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const resultValid = validNotNull([
        { key: "email", value: email },
        { key: "password", value: password },
      ]);

      setErrors(resultValid.errorsValidation);
      if (resultValid.error) {
        return notify(translateUtils.notifyValidRequired);
      }

      setLoading(true);

      let response = await auth({ email: email.trim(), password });

      if (response.code === 401) {
        notify(response.message);
      }

      const userObjectJSON = JSON.stringify(response?.data?.user);
      localStorage.setItem("userLogged", userObjectJSON);

      if (
        response.code === 401 &&
        response.message === "Redefinição de senha necessaria!"
      ) {
        setSessionCognito(response.data?.session || "");
        return push("/changepassword", {
          email: email,
          isGfTrialFirstAccess: isGfTrialFirstAccess?.current,
        });
      }

      if (
        response.code === 401 &&
        response.message === "Usuario não confirmado!"
      ) {
        return push("/firstlogin", { sendCodeVerif: true });
      }

      if (
        response.code === 401 &&
        response.message === "Usuario não encontrado!"
      ) {
        return push("/firstlogin");
      }

      if (
        response.code === 401 &&
        response.message === "Usuario existe mas não na base de dados!"
      ) {
        return Swal.fire({
          icon: "info",
          html: "<p>Seu usuário está cadastrado em nossa base de acesso, porem não está vinculada à nenhuma empresa. </p> <p>Verifique se o email utilizado é o mesmo que foi liberado o produto, ou solicite ao usuário master da empresa a liberação. </p> <p> Caso contrário entre em contato com nosso suporte técnico.</p>",
          showConfirmButton: true,
          timer: 5000,
        });
      }

      response = response.data;

      const tokenDecode = jwt_decode(response?.auth?.cognito?.IdToken || "");

      const user = {
        token: response?.auth?.token || "",
        email: response?.auth?.user_email || "",
        cognito: { ...(response?.auth?.cognito || {}), ...(tokenDecode || {}) },
        userId: response?.user?.id || "",
        firstName: response?.user?.first_name || "",
        lastName: response?.user?.last_name || "",
        lastLogin: response?.user?.last_login || "",
        tokenCognito: response?.auth?.cognito?.AccessToken || "",
        subCognito: tokenDecode?.sub || "",
        showVideoIntro: (!!response?.user?.show_video_intro).toString(),
      };

      setUserLogged(user);

      let entities = await getEntities(response.user.id);

      // filtra empresas duplicadas
      const userEntities = entities.data.entities
        ? [
            ...new Map(
              entities.data.entities.map((entity) => [entity["id"], entity])
            ).values(),
          ]
        : [];

      if (userEntities.length === 0) {
        return Swal.fire({
          icon: "info",
          html: "<p>Seu usuário está cadastrado em nossa base de acesso, porem não está vinculada à nenhuma empresa. </p> <p>Verifique se o email utilizado é o mesmo que foi liberado o produto, ou solicite ao usuário master da empresa a liberação. </p> <p> Caso contrário entre em contato com nosso suporte técnico.</p>",
          showConfirmButton: true,
          timer: 5000,
        });
      }

      if (userEntities.length === 1) {
        await Promise.all([
          syncEntitySystemVersions({ entityId: userEntities[0].id }),
          syncEntityUser(userEntities[0].id),
        ]);

        await handleSelectEntity({
          list: userEntities,
          companyIdParam: userEntities[0].id.toString(),
          userIdParam: response?.user?.id,
          redirectedFromLogin: true,
          isGfTrialFirstAccess: isGfTrialFirstAccess?.current,
        });

        return;
      }

      setListCompanies(userEntities);

      return push("/companyselection", {
        isGfTrialFirstAccess: isGfTrialFirstAccess?.current,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendCodeVerif = async (e) => {
    try {
      setLoading(true);

      const respRecover = await postCodeRecover({ body: { email: email } });

      if (respRecover?.success) {
        setSendCodeVerif(true);
        return notify(
          "Código de verificação enviado no e-mail informado.",
          true,
          "success"
        );
      } else {
        setSendCodeVerif(false);
        return notify(
          respRecover?.message ||
            "Ocorreu um erro ao enviar o código de verificação, por favor, entre em contato com o suporte, ou tente novamente mais tarde.",
          false,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePasswordRecover = async (e) => {
    try {
      setLoading(true);

      const valid = validNotNull([
        { value: verificationCode, key: "verificationCode" },
        { value: newPassword, key: "newPassword" },
        { value: confirmNewPassword, key: "confirmNewPassword" },
      ]);

      if (newPassword !== confirmNewPassword) {
        valid.error = true;
        valid.errorsValidation.confirmNewPassword = "A senha não coincide";
      }

      if (validPasswordCoginito(newPassword).error) {
        valid.error = true;
        valid.errorsValidation.newPassword =
          "A senha deve conter letras maiúsculas, minúsculas e números";
      }

      setErrors(valid.errorsValidation);

      if (valid.error) return;

      const respChangPass = await postChangePassRecover({
        body: {
          code: verificationCode.trim(),
          email: email.trim(),
          password: newPassword,
        },
      });

      if (respChangPass?.success) {
        notify("Senha atualizada com sucesso.", true, "success");

        setTimeout(() => {
          push("/login", { logout: true });
        }, 1000);
      } else {
        notify(
          respChangPass?.message || "Ocorreu um erro ao recuperar a senha",
          true,
          "warning"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleRadioChange = (event) => {
    setCompanyChecked(event.target.value);
  };

  const handleSelectEntity = async ({
    list,
    companyIdParam,
    userIdParam,
    redirectedFromLogin,
    isGfTrialFirstAccess,
  }) => {
    try {
      setLoading(true);
      const companyId = companyIdParam || companyChecked;
      const userId = userIdParam || userLogged.userId;

      if (!companyId) return notify(translate.notifySelectCompany);

      const companyUser = (list || listCompanies).find(
        (company) => company.id.toString() === companyId
      );

      await Promise.all([
        syncEntitySystemVersions({ entityId: companyId }),
        syncEntityUser(companyId),
      ]);

      localStorage.setItem("companyIdPanel", companyUser?.id);
      let getEntityCall = null;
      if (companyUser?.main_entity_id) {
        getEntityCall = getEntityDataById(companyUser?.main_entity_id);
      }

      if (companyUser?.accountant_id) {
        getEntityCall = getEntityDataById(companyUser?.accountant_id);
      }

      const [permissionTree, permissions, mainEntity, systemEntityLogged] =
        await Promise.all([
          getPermissionTree(companyUser.document),
          getUserPermissions(companyUser.document, userId),
          getEntityCall,
          getSystemsEntity({
            id: companyUser?.id,
          }),
        ]);

      if (permissionTree) {
        savePermissionTreeOnLocalStorage(permissionTree);
      }

      if (permissions) {
        saveUserPermissionsOnLocalStorage(permissions);
      }

      const others = { systemsPermissions: [] };
      if (companyUser?.main_entity_id) {
        others.mainEntityCnpj = mainEntity?.data?.document || "";
      }

      if (companyUser?.accountant_id) {
        others.accountantCnpj =
          mainEntity?.data?.document || companyUser?.accountant_document || "";
        others.accountantName =
          mainEntity?.data?.trading_name || companyUser?.accountant_name || "";
        others.accountantLogoUrl =
          mainEntity?.data?.logo_url || companyUser?.accountant_logo_url || "";
      }

      if (userId && companyUser?.id) {
        let systemsCompany = null;

        if (companyUser?.is_accountant) {
          systemsCompany = systemEntityLogged;
        } else {
          let systemsAccountant = null;

          if (companyUser?.accountant_id) {
            systemsAccountant = await getSystemsEntity({
              id: companyUser?.accountant_id,
            });
          }

          let systemsCompanyLogged = systemEntityLogged;

          let allowedSystems = systemsAccountant ?? systemsCompanyLogged;

          if (systemsAccountant?.data && systemsCompanyLogged?.data) {
            systemsAccountant.data.forEach((element) => {
              systemsCompanyLogged.data.forEach((element2) => {
                if (
                  element.id === element2.id &&
                  element.status_product !== "A"
                ) {
                  element.status_product = element2.status_product;
                  element.configured = element2.configured;
                }
              });
            });
          }

          systemsCompany = {
            success: true,
            data: (Array.isArray(allowedSystems?.data)
              ? allowedSystems?.data
              : []
            ).map((systAcc) => ({
              ...systAcc,
            })),
          };
        }

        if (systemsCompany?.success && Array.isArray(systemsCompany?.data)) {
          systemsCompany = systemsCompany?.data;
        } else systemsCompany = [];

        others.systemsPermissions = systemsCompany;
      }

      const compUser = {
        masterUserId: companyUser?.master_user_id,
        masterUserEmail: companyUser?.master?.email || "",
        companyId: companyUser?.id,
        document: companyUser?.document,
        logoB64: companyUser?.logo_url,
        companyName: companyUser?.company_name,
        tradingName: companyUser?.trading_name,
        taxRegimeId: companyUser?.tax_regime_id,
        mainEntityId: companyUser?.main_entity_id,
        accountantId: companyUser?.accountant_id,
        logoUrl: companyUser?.logo_url,
        entityType: companyUser?.entity_type,
        plug: companyUser?.plug,
        isAccountant: companyUser?.is_accountant,
        pivot: companyUser?.pivot,
        subdomain: companyUser?.subdomain ?? "",
        websiteExhibitionType: companyUser?.website_exhibition_type ?? "qyon",
        ownWebsiteUrl: companyUser?.own_website_url ?? "",
        isGfTrialFirstAccess: isGfTrialFirstAccess ? "true" : "false",
        ...others,
      };

      setCompanyUser(compUser);

      localStorage.setItem("environment", process.env.REACT_APP_ENVIRONMENT);
      localStorage.setItem("subdomain", companyUser?.subdomain ?? "");

      if (localStorage.getItem("nextPageLoginPanel")) {
        const urlNextPage = localStorage.getItem("nextPageLoginPanel");
        localStorage.setItem("nextPageLoginPanel", "");
        return window.location.replace(urlNextPage);
      }
      //FIXME
      const uri = sessionStorage.getItem("lastUriPanel") || "/dashboard/view";
      sessionStorage.setItem("lastUriPanel", "");

      if (redirectedFromLogin)
        localStorage.setItem("redirectedFromLogin", "true");

      window.location.href = `${
        process.env.REACT_APP_ENV_DEV ? "http" : "https"
      }://${window.location.host}${uri}`;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearContextAuth = useCallback(() => {
    setEmail("");
    setPassword("");
    setCompanyChecked("");
    setListCompanies([]);
  }, [setCompanyChecked, setEmail, setListCompanies, setPassword]);

  const handleSetCustomColumns = useCallback(
    (orderColumnsFromLocalStorage, checkedColumnsFromLocalStorage) => {
      const defaultValues = {
        orderColumns: [
          { name: "Ações", id: 1 },
          { name: "Acesso Portal", id: 2 },
          { name: "Gestão Fácil", id: 3 },
          { name: "Acesso GF", id: 4 },
          { name: "Cliente", id: 5 },
          { name: "Mensalidade", id: 6 },
          { name: "Usuários", id: 7 },
          { name: "Certificado Digital", id: 8 },
          { name: "Cont. Pagar", id: 9 },
          { name: "Cont. Receber", id: 10 },
          { name: "NF. Entrada", id: 11 },
        ],
        columnsCheck: {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
          11: true,
        },
      };

      let columnsCheck = defaultValues.columnsCheck;
      let orderedColumns = orderColumnsFromLocalStorage;

      if (
        Object.keys(checkedColumnsFromLocalStorage).length ===
        Object.keys(defaultValues.columnsCheck).length
      ) {
        columnsCheck = checkedColumnsFromLocalStorage;
      }

      const checkedColumns = defaultValues.orderColumns.filter(
        (column) => columnsCheck[`${column.id}`]
      );

      if (checkedColumns.length !== orderColumnsFromLocalStorage.length) {
        orderedColumns = checkedColumns;
      }
      localStorage.setItem(
        "orderColumns",
        JSON.stringify({
          orderColumns: orderedColumns,
        })
      );

      localStorage.setItem(
        "checkColumns",
        JSON.stringify({
          checkColumns: columnsCheck,
        })
      );
    },
    []
  );
  const handleLogout = useCallback(() => {
    const nextPageLoginPanel = localStorage.getItem("nextPageLoginPanel");
    const orderColumnsFromLocalStorage =
      JSON.parse(localStorage.getItem("orderColumns"))?.orderColumns ?? [];
    const checkedColumnsFromLocalStorage =
      JSON.parse(localStorage.getItem("checkColumns"))?.checkColumns ?? {};

    clearUserLogged();
    clearCompanyUser();
    const lastViewedVersion = localStorage.getItem("lastViewedVersion");
    const dontShowNewLetters = localStorage.getItem("dontShowNewLetters");

    localStorage.clear();

    sessionStorage.removeItem("hostPanel");
    sessionStorage.removeItem("website_exhibition_type");
    sessionStorage.removeItem("own_website_url");
    sessionStorage.removeItem("promo_video_url");

    handleSetCustomColumns(
      orderColumnsFromLocalStorage,
      checkedColumnsFromLocalStorage
    );

    if (lastViewedVersion)
      localStorage.setItem("lastViewedVersion", lastViewedVersion);

    if (dontShowNewLetters)
      localStorage.setItem("dontShowNewLetters", dontShowNewLetters);

    localStorage.setItem("nextPageLoginPanel", nextPageLoginPanel || "");
  }, [clearCompanyUser, clearUserLogged, handleSetCustomColumns]);
  const handleChangePassword = async (event) => {
    let successChange = false;
    try {
      event.preventDefault();

      setLoading(true);

      const valid = validNotNull([
        { value: password, key: "password" },
        { value: confirmNewPassword, key: "confirmNewPassword" },
      ]);

      if (password !== confirmNewPassword) {
        valid.error = true;
        valid.errorsValidation.confirmNewPassword = "A senha não coincide";
      }

      if (validPasswordCoginito(password).error) {
        valid.error = true;
        valid.errorsValidation.password = "Formato de senha inválido";
      }

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const respChangPass = await changePasswordFirstTime({
        body: {
          session: sessionCognito,
          email: email.trim(),
          password: password,
        },
      });

      if (respChangPass?.success) {
        successChange = true;
        notify("Senha atualizada com sucesso.", true, "success");
      }
    } catch (error) {
      console.error(error);
    } finally {
      successChange ? handleSubmit(event) : setLoading(false);
    }
  };

  return {
    //Login
    handleSubmit,
    handleChangeEmail,
    handleClearContextAuth,

    //Logout
    handleLogout,

    // handleConfirmUser,

    //CompanySelection
    handleSelectEntity,
    handleRadioChange,

    //RecoverPassword
    handleSendCodeVerif,
    handleChangePasswordRecover,

    //ChangePassword,
    handleChangePassword,

    isGfTrialFirstAccess,
  };
}
